import { mapHelper } from "@/utils/utils.js";

// 	星期
const weekArr = [
  {
    value: 1,
    text: "星期一",
  },
  {
    value: 2,
    text: "星期二",
  },
  {
    value: 3,
    text: "星期三",
  },
  {
    value: 4,
    text: "星期四",
  },
  {
    value: 5,
    text: "星期五",
  },
  {
    value: 6,
    text: "星期六",
  },
  {
    value: 0,
    text: "星期日",
  },
];
const { map: weekArrMap, setOps: weekArrOps } = mapHelper.setMap(weekArr);

// 	星期
const weekList = [
  {
    value: "星期一",
    text: "1",
  },
  {
    value: "星期二",
    text: "2",
  },
  {
    value: "星期三",
    text: "3",
  },
  {
    value: "星期四",
    text: "4",
  },
  {
    value: "星期五",
    text: "5",
  },
  {
    value: "星期六",
    text: "6",
  },
  {
    value: "星期日",
    text: "7",
  },
];
const { map: weekListMap, setOps: weekListOps } = mapHelper.setMap(weekList);
// 	预约状态
const appointStatusArr = [
  {
    value: 1,
    text: "预约成功",
  },
  {
    value: 2,
    text: "预约失败",
  },
  {
    value: 0,
    text: "预约中",
  },
];
const { map: appointStatusArrMap, setOps: appointStatusArrOps } =
  mapHelper.setMap(appointStatusArr);
// 	审核状态
const courseStatusArr = [
  {
    value: 1,
    text: "审核成功",
  },
  {
    value: 2,
    text: "审核失败",
  },
  {
    value: 0,
    text: "审核中",
  },
];
const { map: courseStatusArrMap, setOps: courseStatusArrOps } =
  mapHelper.setMap(courseStatusArr);
export { weekArr, weekArrMap, appointStatusArrMap, weekListMap, courseStatusArrMap };
