<template>
  <div class="pic-box" v-if="picList && picList.length">
    <div class="row" v-for="(row, pIndex) in destPicList" :key="pIndex">
      <!-- <template > -->
      <van-image
        v-for="(pic, subIndex) in row"
        :key="`${pIndex}_${subIndex}`"
        :fit="fit"
        class="pic-img"
        :src="`${oOrigin}/imageproxy/${handleWdith}x${handleWdith}/${pic}`"
        :style="{
          width: handleWdith + 'px',
          height: handleWdith + 'px',
          marginRight: marginWidth,
        }"
        @click="itemClick(pIndex, subIndex, picList, pic)"
      >
        <template v-slot:error>加载失败</template>
      </van-image>
      <!-- </template> -->
    </div>
  </div>
</template>

<script>
import { Image as vanImage } from "vant";
import { ImagePreview } from "vant";
import "vant/lib/image/style";
import { origin } from "../../../public/config/request.js";

export default {
  props: {
    // 图片缩放方式
    fit: {
      type: String,
      default: "scale-down",
    },
    // 图片地址
    picList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 每行图片数量
    columns: {
      type: Number,
      default: 3,
    },
    // 图片间的左间距
    margin: {
      type: Number,
      default: 5,
    },
    // 每张图片的高度
    imgHeight: {
      type: Number,
      default: 180,
    },
    // 压缩率
    compress: {
      type: Number,
      default: 70,
    },
    // 图片
    imagePreview: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    "van-image": vanImage,
  },
  data() {
    return {
      handleWdith: "",
      oOrigin: "",
    };
  },
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.oOrigin = origin;
    } else {
      this.oOrigin = window.location.origin;
    }
    this.getWidth();
  },
  computed: {
    imgWidthStr() {
      let columns = this.columns;
      return `${(100 - (columns - 1) * this.margin) / columns}%`;
    },
    imgHeightStr() {
      const baseUnit = this.$baseUnit;
      return `${this.imgHeight / baseUnit}rem`;
    },
    marginWidth() {
      return `${this.margin}%`;
    },
    // 将图片数组转化为二维数组
    destPicList() {
      let picList = this.picList;
      let columns = this.columns;
      let destPicList = [];
      if (picList && picList.length) {
        let len = picList.length;
        let division = parseInt(len / columns);
        let mode = len % columns;
        let rows = mode === 0 ? division : division + 1;
        for (let i = 0; i < division; i++) {
          let rowArr = [];
          for (let j = 0; j < columns; j++) {
            rowArr.push(picList[j + i * columns]);
          }
          destPicList.push(rowArr);
        }
        if (mode !== 0) {
          let rowArr = [];
          for (let j = 0; j < mode; j++) {
            rowArr.push(picList[j + division * columns]);
          }
          destPicList.push(rowArr);
        }
      }
      return destPicList;
    },
  },
  methods: {
    getWidth() {
      let oWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      let biLie = oWidth / 375;
      let totalWidth = oWidth - biLie * (16 * 2 + 36 + 8);
      let everyWidth = (totalWidth - totalWidth * 0.05 * 2) / 3;
      this.handleWdith = everyWidth.toFixed(2);
    },
    itemClick(pIndex, subIndex, imgUrls, pic) {
      let columns = this.columns;
      let index = pIndex * columns + subIndex;
      if (this.imagePreview) {
        ImagePreview({
          images: imgUrls,
          startPosition: index,
        });
      }
      this.$emit("imgClick", imgUrls, index, pic);
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  text-align: left;
  &:not(:last-child) {
    padding-bottom: 32px;
  }
  .pic-img {
    background: #000;
    float: left;
    position: relative;
    &:last-child {
      margin-right: 0 !important;
    }
    .clear_pic {
      width: 30px;
      height: 30px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      border-radius: 50%;
    }
    > img {
      // object-fit: contain;
      // width: 100%;
      // height: 100%;
    }
  }
}
</style>
